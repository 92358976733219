import React, { useRef } from 'react';
import clsx from 'clsx';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//styles
import styles from '../services.module.css';
//components
import BulletedList from 'components/bullets/bulleted-list';
import { UnderlineBullets } from 'components/bullets/titled-bullets';
import Bullets from 'components/bullets';
import Layout from 'components/services/components/layout';
import Blockquote from 'components/services/components/blockquote';
import TestingTools from './testing-tools';
import Booking from 'components/feedback';
//meta
import { meta } from 'metaData';
import identificators from 'components/googleAnalyticsIdentificators';
//assets
import comprehensiveTesting from './comprehensive-testing.json';
import tailoredExpertise from './tailored-expertise.json';
import qaReports from './qa-reports.json';
import testingProcess from './testing-process.json';

const keyDeliverables = [
  {
    item: 'Test strategy and test plan',
  },
  {
    item: 'Integrated suite of automated testing tools',
  },
  {
    item: 'Reusable automation test scripts',
  },
  {
    item: 'KPI-based test result reports',
  },
];
const testingMethods = [
  {
    item: 'Efficiency & speed',
  },
  {
    item: 'Cost-effectiveness',
  },
  {
    item: 'Improved feedback loops',
  },
];

const QaService = ({ location }: { location: Location }) => {
  const data = useStaticQuery(graphql`
    query {
      timper: file(relativePath: { eq: "timper.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      areaButler: file(relativePath: { eq: "area-butler.svg" }) {
        publicURL
      }
      tarmo: file(relativePath: { eq: "tarmo.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      truck4goods: file(relativePath: { eq: "truck4goods.svg" }) {
        publicURL
      }
      testingKpi: file(relativePath: { eq: "testing-kpis.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      GregChristian: file(relativePath: { eq: "GregChristian.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      offerMob: file(relativePath: { eq: "qa-offering-mob.svg" }) {
        publicURL
      }
      offer: file(relativePath: { eq: "qa-offering.svg" }) {
        publicURL
      }
    }
  `);

  const breakpoint = useBreakpoint();
  const bookingRef = useRef<HTMLDivElement>(null);
  const handleClickCTA = () => {
    if (bookingRef.current !== null) {
      bookingRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout
      title="Software Testing Services&nbsp;— &#10;Dedicated QA for Your Project"
      subtitle="Hire our dedicated QA team for end-to-end software testing. We seamlessly integrate into your workflow ensuring smooth collaboration and predictable results."
      btnText="Hire our QA experts"
      metaData={{ main: meta.qaServices }}
      pathname={location.pathname}
      titleStyles={styles.wideTitle}
      subtitleStyles={styles.wideSubtitle}
    >
      <section className={styles.section}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.titleContainer}>
            Hire an Expert QA&nbsp;Team{'\nfor Comprehensive Software Testing'}
          </p>
          <p className={styles.lightParagraph}>
            Manual, automated, functional, and non-functional – we handle all kinds of software
            testing. With our dedicated QA team, you can ensure comprehensive coverage for your
            product, save on your quality assurance budget, and speed up development.
          </p>
          <BulletedList
            list={comprehensiveTesting}
            listStyle={styles.comprehensiveTestingList}
            itemStyle={styles.comprehensiveTestingItem}
          />
          <Link
            id={identificators.HIRE_QA}
            className={clsx('btn btn_60 btn__red btnXL', styles.link)}
            to="/get-in-touch/"
          >
            Hire our QA experts
          </Link>
        </div>
      </section>
      <section className="section">
        <Blockquote
          text="“After working together for 4 months Brocoders is fully part of our team. The service delivered is of very high quality. The execution is quick and includes very close communication.”"
          img={data.timper.childImageSharp.fluid}
          isLogo={true}
          logoImg={data.areaButler.publicURL}
          innerClassName={styles.blockquote}
          isRating
          isWaveOnTop
          author={
            <React.Fragment>
              <strong>Alexander Timper </strong> <br />
              CEO &amp; Founder,{' '}
              <a
                href="https://area-butler.de/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.BqLink}
              >
                AreaButler
              </a>
            </React.Fragment>
          }
        />
      </section>
      <section className={styles.section}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.titleContainer}>
            For products in production and those in development
          </p>
          <p className={styles.lightParagraph}>
            Whether your product is already in production or you're at the initial stages of product
            development, we offer tailored solutions to improve your software quality.
          </p>
          <ul className={clsx(styles.productsList, styles.containerMargin)}>
            <li className={styles.productsItem}>
              <p className={styles.productsTitle}>For products in production</p>
              <p className={styles.productsTextRed}>
                Our approach depends on the specific tasks we are hired for.
              </p>
              <p className={styles.productsText}>
                If your product is already in the hands of users, our QA experts will perform
                thorough evaluations to identify potential issues, improve existing functionality,
                and enhance overall user satisfaction.
              </p>
            </li>
            <li className={styles.productsItem}>
              <p className={styles.productsTitle}>For products currently being built</p>
              <p className={styles.productsTextRed}>
                A framework for QA and test automation with the needed tools and processes.
              </p>
              <p className={styles.productsText}>
                Develop your product with confidence. Our QA teams will work as part of your
                development process, implementing testing protocols to ensure a solid QA process for
                your product.
              </p>
            </li>
          </ul>
          <Link
            id={identificators.HIRE_QA}
            className={clsx('btn btn_60 btn__red btnXL', styles.link)}
            to="/get-in-touch/"
          >
            Hire our QA experts
          </Link>
        </div>
      </section>
      <section className={clsx(styles.section, styles.emphasisContainer)}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.whiteTitle}>Combined testing methods</p>
          <p className={clsx(styles.lightParagraph, styles.lightEmphasisParagraph)}>
            We perform both manual and automated testing striking the right balance between these
            two approaches to deliver a cost-efficient testing strategy while ensuring robust
            quality, agility, and a faster time-to-market for your software products.
          </p>
          <BulletedList
            list={testingMethods}
            listStyle={clsx(styles.comprehensiveTestingList, styles.methodsList)}
            itemStyle={clsx(styles.comprehensiveTestingItem, styles.methodsItem)}
          />
          <ul className={styles.productsList}>
            <li className={clsx(styles.productsItem, styles.testingMethodsItem)}>
              <p className={clsx(styles.productsTextRed, styles.testingMethodsText)}>
                Automated testing
              </p>
              <p className={clsx(styles.productsText, styles.testingMethodsText)}>
                Accelerate your testing processes reducing time-to-market by automating repetitive
                and time-consuming test scenarios.
              </p>
            </li>
            <li className={clsx(styles.productsItem, styles.testingMethodsItem)}>
              <p className={clsx(styles.productsTextRed, styles.testingMethodsText)}>
                Manual testing
              </p>
              <p className={clsx(styles.productsText, styles.testingMethodsText)}>
                Leverage human intuition to identify complex issues that automated tools might
                overlook, ensuring thorough testing.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.section}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.titleContainer}>Tailored expertise when you need it</p>
          <p className={styles.lightParagraph}>
            Each project is unique, and testing needs may vary. Whether you require focused
            expertise in functional, performance, Unit, or API testing, we offer an on-demand
            testing team with the required expertise.
          </p>
          <Bullets
            list={tailoredExpertise}
            className={styles.tailoredBullets}
            itemStyle={styles.tailoredBulletItem}
          />
        </div>
      </section>
      <section className="section">
        <Blockquote
          text="“Working with such a professional team is a pleasure, nothing they could have done differently to make more effective results.”"
          img={data.tarmo.childImageSharp.fluid}
          isLogo={true}
          logoImg={data.truck4goods.publicURL}
          innerClassName={styles.blockquote}
          isRating
          isWaveOnTop
          author={
            <React.Fragment>
              <strong>Tarmo Maasel</strong> <br />
              Founder,{' '}
              <a href="https://truck4goods.com/" target="_blank" className={styles.red}>
                Truck4goods
              </a>
            </React.Fragment>
          }
        />
      </section>
      <section className={styles.section}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.subtitle}>Transparent reports on KPIs for measurable success</p>
          <p className={styles.lightParagraph}>
            We track changes, gather KPIs, and measure the success of our testing efforts, keeping
            you informed about the performance and quality metrics of your software, and the
            efficiency of our testing process.
          </p>
          <Img
            fluid={data.testingKpi.childImageSharp.fluid}
            className={clsx(styles.imgTesting)}
            alt=""
          />
          <p className={styles.imgCaption}>
            A carousel with screenshots of the reports with the following KPIs: # of rollbacks, # of
            hotfixes, and # of regression bugs
          </p>
          <Bullets
            list={qaReports}
            className={styles.reportBullets}
            itemStyle={styles.reportItem}
          />
        </div>
      </section>
      <section className="section gray">
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.titleContainer}>How our QA and testing process works</p>
          <p className={styles.lightParagraph}>
            Typically, we collaborate with product owners or QA leads within our clients' teams,
            tailoring our process to align with their distinct requirements. Below is a summary of
            our standard testing procedure:
          </p>
          <UnderlineBullets list={testingProcess} />
        </div>
      </section>
      <section className={clsx(styles.section, styles.redSection, styles.largeSection)}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.whiteTitle}>Key deliverables</p>
          <p className={clsx(styles.lightParagraph, styles.whiteText)}>
            Our key deliverables are not just documents; they are strategic assets that empower your
            team with the information needed to make informed decisions, optimize processes, and
            ensure the delivery of high-quality software solutions.
          </p>
          <BulletedList
            list={keyDeliverables}
            listStyle={clsx(styles.comprehensiveTestingList, styles.deliverablesList)}
            itemStyle={clsx(styles.comprehensiveTestingItem, styles.deliverablesItem)}
          />
        </div>
      </section>
      <section className={clsx(styles.section, styles.blockquoteSection)}>
        <Blockquote
          text="“Brocoders delivered a platform that received praise from users and increased the efficiency of several business processes. The product facilitates a reduction in production errors, which encourages happier customers.”"
          img={data.GregChristian.childImageSharp.fluid}
          innerClassName={styles.blockquote}
          isWaveOnTop
          author={
            <React.Fragment>
              <strong>Greg Christian</strong>
              <br />
              CEO,{' '}
              <a href="https://beyondgreenpartners.com/" target="__blank" className="link">
                Beyond Green Sustainable Food Partners
              </a>
            </React.Fragment>
          }
        />
      </section>
      <section className={styles.section}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.titleContainer}>Our software testing toolset</p>
          <p className={styles.lightParagraph}>
            We choose technology stacks according to your project requirements. Explore the QA
            testing tools we employ for various testing purposes.
          </p>
          <TestingTools />
        </div>
      </section>
      <div className={clsx(styles.containerOffer, styles.qaOffer)}>
        <div className={styles.offerQa}>
          {breakpoint.s ? (
            <img src={data.offerMob.publicURL} className={styles.offerIcon} alt="offer picture" />
          ) : (
            <img src={data.offer.publicURL} alt="offer picture" />
          )}
          <button
            id={identificators.HIRE_QA}
            className={clsx('btn btn_60 btn__black btnXL', styles.qaLink)}
            onClick={handleClickCTA}
          >
            Hire our QA experts
          </button>
        </div>
      </div>
      <Booking ctaRef={bookingRef} />
    </Layout>
  );
};
export default QaService;
