import * as React from 'react';
import clsx from 'clsx';
//styles
import styles from 'components/technologies/technologies.module.css';
//assets
import Selenium from 'images/icons/selenium.inline.svg';
import Cucumber from 'images/icons/cucumber.inline.svg';
import Appium from 'images/icons/appium.inline.svg';
import Cypress from 'images/icons/cypress.inline.svg';

import Jasmine from 'images/icons/jasmine.inline.svg';
import Jmeter from 'images/icons/jmeter.inline.svg';
import Browserstack from 'images/icons/browserstack.inline.svg';
import Locust from 'images/icons/locust.inline.svg';
import Perfecto from 'images/icons/perfecto.inline.svg';
import SauceLab from 'images/icons/sauceLab.inline.svg';

function TestingTools() {
  return (
    <ul className={styles.qaTools}>
      <li className={styles.qaToolGroup}>
        <p className={styles.techToolsName}>Automation testing tools</p>
        <ul className={clsx(styles.techToolList, styles.wrappedList)}>
          <li className={styles.techToolItem}>
            <Selenium />
          </li>
          <li className={styles.techToolItem}>
            <Cucumber />
          </li>
          <li className={styles.techToolItem}>
            <Appium />
          </li>
          <li className={styles.techToolItem}>
            <Cypress />
          </li>
          <li className={clsx(styles.techToolItem, styles.jasmineSvg)}>
            <Jasmine />
          </li>
        </ul>
      </li>
      <li className={styles.qaToolGroup}>
        <p className={styles.techToolsName}>Performance testing tools</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <Jmeter />
          </li>
          <li className={styles.techToolItem}>
            <Locust />
          </li>
        </ul>
      </li>
      <li className={styles.qaToolGroup}>
        <p className={styles.techToolsName}>Compatibility and browser testing tools</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <Browserstack />
          </li>
          <li className={styles.techToolItem}>
            <SauceLab />
          </li>
          <li className={styles.techToolItem}>
            <Perfecto />
          </li>
        </ul>
      </li>
    </ul>
  );
}

export default TestingTools;
